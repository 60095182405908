.home {
  background-color: #fcfcfc;
  width: 100%;
  height: 100vh; /* Adjust for full screen height on any device */
  font-family: "Lalezar", system-ui;
  font-weight: 400;
  font-style: normal;
  user-select: none;
}

.home .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: calc(100vh - 90px); /* Adjust height for smaller screens */
}

.home .title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: auto;
}

.home #heading {
  color: #29193e;
  font-size: 128px;
  margin-bottom: -24px;
  margin-top: 0;
  text-shadow: 0px 0px 250px rgb(0, 0, 0);
}

.home #subheading {
  color: #c8a9f1;
  font-size: 24px;
  margin: 20px 0;
  text-shadow: 0px 0px 250px rgb(0, 0, 0);
}

.home #venue {
  color: #8c74b7;
  font-size: 24px;
  margin: auto;
  text-shadow: 0px 0px 250px rgb(0, 0, 0);
  user-select: text;
}

.home .buttons {
  display: flex;
  flex-direction: row;
  font-size: 28px;
}

.home .button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: black;
}

.home .deadline {
  margin: 0 0;
}

.home .button {
  width: 320px;
  height: 80px;
  margin: 48px 96px;
  margin-bottom: 10px;
  border-radius: 100px;
  box-shadow: 0px 0px 250px hsl(0, 0%, 25%);
  text-align: center;
  align-content: center;
  z-index: 1;
  color: white;
  font-size: 28px;
}

.home #student {
  background-color: hsl(257, 25%, 45%);
  /* background-color: #8c74b7;
  opacity: 0.5; */
}

.home #company {
  background-color: #8c74b7;
  opacity: 0.5;
}

/*
.home #company:hover {
  box-shadow: 0px 0px 10px hsl(0, 0%, 75%), 0px 0px 250px hsl(0, 0%, 25%);
  background-color: hsl(261, 32%, 62%);
}
*/

.home #student:hover {
  box-shadow: 0px 0px 10px hsl(0, 0%, 75%), 0px 0px 250px hsl(0, 0%, 25%);
  background-color: hsl(257, 25%, 55%);
}

.home a {
  text-decoration: none;
  color: inherit;
}

/* Mobile Layout */


@media only screen and (max-width: 1100px) {
  .home {
    height: 100dvh; /* Allow height to adjust based on content */
    display: flex;
    flex-direction: column;
  }

  .home .content {
    flex-direction: column; /* Stack content vertically */
    justify-content: center;
    height: auto;
    margin: 20px;
    flex-grow: 1;
    align-items: center;
  }

  .home #heading {
    font-size: 85px; /* Smaller font size for mobile */
  }

  .home #subheading,
  .home #venue {
    font-size: 18px; /* Adjust font sizes */
    margin: 10px 0;
  }

  .home .buttons {
    flex-direction: column; /* Stack buttons */
  }

  .home .button {
    width: 90%; /* Full-width buttons */
    height: 60px;
    margin: 20px 0; /* Adjust margins */
    font-size: 20px;
  }

  .home .button-group {
    margin: 10px 0;
  }

  .home .deadline {
    font-size: 16px;
  }
}


@media only screen and (max-width: 712px) {
  .home #heading {
    font-size: 50px;
  }

  .home #subheading {
    font-size: 18px;
  }

  .home #venue {
    font-size: 18px;
  }

  .home .button {
    width: 200px;
    height: 60px;
    margin: 20px;
    font-size: 20px;
  }

  .home .button-group {
    margin: 10px;
  }

  .home .deadline {
    font-size: 16px;
  }
  
}

@media only screen  and (max-width: 437px) {
  .home #heading {
    font-size: 36px;
  }

  .home #subheading {
    font-size: 18px;
  }

  .home #venue {
    font-size: 18px;
  }

  .home .button {
    width: 200px;
    height: 60px;
    margin: 20px;
    font-size: 20px;
  }

  .home .button-group {
    margin: 10px;
  }

  .home .deadline {
    font-size: 16px;
  }
  
}
