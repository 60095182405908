.footer {
    background-color: #FCFCFC;
    width: 100%;
    font-family: "Lalezar", system-ui;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    user-select: none;
}

/* Contact Section */
.footer .contact {
    display: flex;
    flex-direction: column;
    background-color: #D8C1F7;
    width: 100%;
    height: 200px;
    font-size: 48px;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 150px rgb(0, 0, 0);
    margin-top: -20px;
    padding-top: 20px;
    z-index: 1;
    user-select: text;
}

.footer .contact p {
    margin: 0;
    padding: auto;
    text-align: center;
}

/* TESC Section */
.footer .tesc {
    background-color: #000000;
    height: 485px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    padding: 0 20px;
    text-align: center;
}

#organized {
    font-family: "League Spartan", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 32px;
}

.footer #desc {
    font-size: 24px;
    max-width: 1080px;
    text-align: center;
    margin-top: 20px;
}

#logo {
    margin: 20px 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .footer .contact {
        font-size: 32px;
        height: auto;
        padding: 20px 0;
        padding-top: 40px;
    }

    .footer .tesc {
        height: auto;
        padding: 5dvw;
    }

    #organized {
        font-size: 24px;
    }

    .footer #desc {
        font-size: 18px;
    }

    #logo {
        width: 80%;
        height: auto;
    }
}
