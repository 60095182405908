.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d8c1f7;
  width: 100%;
  height: 90px;
  font-family: "Lalezar", system-ui;
  font-weight: 400;
  font-style: normal;
  position: relative; /* Add position relative for absolute positioning inside */
}

.logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  flex-grow: 1;
}

.whitespace {
  display: flex;
  flex-grow: 1;
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: auto 0;
  font-size: calc(min(3.3vw, 50px));
  flex-grow: 3;
}

.navbar p {
  margin: auto 60px;
}

.clickable {
  cursor: pointer;
}

#decaf {
  font-size: 67px;
  margin: auto 0;
  color: #65558f;
}

.header #mug {
  margin-top: 0px;
  margin-right: -32px;
  z-index: 0;
}

/* Mobile Layout */
@media only screen and (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: center;
    height: auto;
    padding: 0 20px;
    width: auto;
  }

  .logo {
    flex-grow: 0;
    justify-content: center;
  }

  /* Hide the navbar by default on mobile */
  .navbar {
    display: none;
    flex-direction: column;
    font-size: 24px;
    position: absolute; /* Position the navbar absolutely */
    top: 85px; /* Place below the header */
    right: 0; /* Align to the right edge */
    background-color: #d8c1f7; /* Match header background */
    width: 100%; /* Full width on mobile */
    border-top: 1px solid #79747e; /* Optional border for separation */
  }

  .navbar.open {
    display: flex; /* Show navbar when hamburger is clicked */
    transition: height 0.3s ease-out; /* Smooth transition */
  }

  .navbar div {
    margin: 10px 0;
    text-align: center;
  }

  #decaf {
    font-size: 48px; /* Smaller logo text on mobile */
    height: auto;
    padding-top: 10px;
  }

  .header #mug {
    width: 64px; /* Reduce the size of the image on mobile */
    margin-right: -20px;
  }

  .clickable {
    font-size: 20px; /* Reduce the font size of navbar items */
  }

  /* Hamburger menu styles */
  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
    cursor: pointer;
  }

  .hamburger .bar {
    width: 100%;
    height: 3px;
    background-color: #333;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .hamburger .bar.open:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }

  .hamburger .bar.open:nth-child(2) {
    opacity: 0;
  }

  .hamburger .bar.open:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }
}
