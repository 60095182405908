.about {
    background-color: #FCFCFC;
    width: 100%;
    height: 100dvh; /* Adjust height for content */
    font-family: "Lalezar", system-ui;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
}

.about .title {
    display: flex;
    background-color: #FCFCFC;
    width: 100%;
    height: 111px;
    font-size: 64px;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 150px rgb(0, 0, 0);
}

.about .content {
    background-color: #D8C1F7;
    max-width: 100%;
    height: auto; /* Adjust height for content */
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    /* padding: 100px 234px; */
}

.about .content .desc p {
    font-size: 24px;
    color: #65558F;
    text-shadow: 0px 0px 100px rgb(0, 0, 0);
    margin: 40px 0;
}

/* .about .content .desc {
    margin-left: 100px;
} */

/* Mobile Layout */
@media only screen and (max-width: 768px) {
    .about .title {
        font-size: 40px; /* Adjust title font size */
        width: auto;
    }

    .about .content {
        flex-direction: column; /* Stack content vertically */
        padding: 20px;
        height: auto;
    }

    .about .content img {
        width: 80%; /* Adjust image size for mobile */
        margin-bottom: 20px;
        display: none;
    }

    .about .content .desc {
        margin-left: 0;
        text-align: center;
    }

    .about .content .desc p {
        font-size: 18px; /* Adjust font size for smaller screens */
        margin: 20px 0;
    }
}
